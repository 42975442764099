import { createApp, h, Component, App } from 'vue';
import singleSpaVue, { SingleSpaProps } from 'single-spa-vue';
import singleSpaCss from 'single-spa-css';

export type BootstrapOptions = {
  /** Standard name of the mfe, kebab, like its folder and package.json */
  name: string;

  /** TODO: support vue router, make this optional */
  mainComponent: Component;

  /** Where in the app frame layout should this module be mounter */
  targetElement?: '#content' | string;
  handleVueApp?: (app: App) => void;
  /**
   * Dynamic loading of stylesheets\
   * Not relevant for local dev (vite serve)\
   * true - Use standard naming - /my-module/my-module-mfe.css\ (based on the `name` prop)
   * string[] - define whatever you want
   */
  includeCss?: boolean | string[];
};

export type LifeCycleFn = (singleSpaProps: SingleSpaProps) => Promise<unknown>;
export type LifeCycleHooks = {
  bootstrap: LifeCycleFn[];
  mount: LifeCycleFn[];
  unmount: LifeCycleFn[];
  update: LifeCycleFn[];
};

/**
 * Helper function for building the single-spa lifecycle hooks\
 * that will connect our microfrontendto the application frame
 *
 * Basically, it takes care of:
 * - mounting your main vue (or router) to the DOM
 * - load relevant stylesheets into the document header
 *
 * Need another hook stage?\
 * just push it to the array(s) retruned by this method
 *
 * NOTE:\
 * any MFE can just export the bootstrap, mount and unmount hooks directly, this is a convenience helper\
 * In extreme situations it might be better to control the MFE hooks manually without this helper
 * @param mfeGenOptions THE OPTIONS
 * @returns The lifecycle hook for each stage - each hook is an array of function single-spa processes one-by-one
 */
export function bootstrapMicrofrontend(
  mfeGenOptions: BootstrapOptions
): LifeCycleHooks {
  const { name, mainComponent, targetElement, handleVueApp, includeCss } =
    mfeGenOptions;

  //////////////////////////
  // MFE lifecycle functions
  const lifecycleHooks = singleSpaVue({
    createApp,
    replaceMode: true, // removes the single-spa-container dom element
    appOptions: {
      el: targetElement,
      render() {
        return h(mainComponent);
      },
    },
    handleInstance: (app) => {
      if (handleVueApp) handleVueApp(app);
    },
  });

  /////////////////////////////////////////////////////////////////////
  // CSS stylesheets - not needed when running locally (except preview)
  let cssUrls: string[] = [];
  if (includeCss === true)
    cssUrls = [`${import.meta.env.VITE_CDN_PREFIX || ''}/${name}/${name}.css`]; // Standard name

  if (Array.isArray(includeCss)) cssUrls = includeCss;

  if (isDevServe()) cssUrls.length = 0;

  const cssLoader = singleSpaCss<SingleSpaProps>({ cssUrls });

  //////////////////////////////////////////////////////////////
  // Pack it up
  // each single-spa lifecycle hook can be an array of functions
  // note the ordering for the different stages
  // css mounted BEFORE; unmounted AFTER
  return {
    // Boot/mount stylesheets before DOM
    bootstrap: [
      cssLoader.bootstrap as LifeCycleFn,
      lifecycleHooks.bootstrap as LifeCycleFn,
    ],
    mount: [
      cssLoader.mount as LifeCycleFn,
      lifecycleHooks.mount as LifeCycleFn,
    ],
    // Remove stylesheets AFTER DOM
    unmount: [
      lifecycleHooks.unmount as LifeCycleFn,
      cssLoader.unmount as LifeCycleFn,
    ],
    update: [lifecycleHooks.update as LifeCycleFn],
  };
}

/**
 * stylesheets are automatically loaded by vite during serve (with HMR!) \
 * We can run a dockerized version locally on port 8080 ([local preview](../../../apps/platform/readme.md))\
 * We want to load css in preview
 */
function isDevServe(): boolean {
  return (
    window.location.host.includes('local') && window.location.port !== '8080'
  );
}
